<template>
  <b-card
  v-if="getUser.work_location_id" >
    <b-modal
        hide-footer
        id="modal-prevent-closing"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalAppointment v-if="id_apoi" :id_appoinment="id_apoi" :modal_close="closeModalReload"/>
    </b-modal>
    <b-modal
        hide-footer
        no-close-on-backdrop
        id="modal-prevent-closing-treatment"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalCreateTreatment v-if="id_treatment" :id_treatment_props="id_treatment"/>
    </b-modal>
    <b-modal
        hide-footer
        no-close-on-backdrop
        id="modal-prevent-closing-treatment-edit"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalEditTreatment v-if="id_treatment" :id_treatment_props="id_treatment"/>
    </b-modal>
    <b-modal
        hide-footer
        id="modal-appointment"
        ref="my-modal"
        :title="`Crear Tratamiento`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalCreateAppointment :date="dateSelect" :doc="doctSelect" :typeAppointment="typeAppointment" :closeModal="closeModalReload"/>
    </b-modal>
    <div class="m-2">
      <b-overlay
          :show="show"
          no-wrap
      />
      <b-row>
        <b-col cols="8" lg="8">
          <a v-if="getUser.appointee_id" href="/nueva/tratamiento/doctor">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Agregar Tratamiento
            </b-button>
          </a>
            <b-button @click="addAppointment" v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Agregar Tratamiento
            </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
              v-if="!validateCheck"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              aria-controls="sidebar-add-new-event"
              @click="changeValidate(true)"
              variant="primary"

          >
            No validados

          </b-button>
          <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              aria-controls="sidebar-add-new-event"
              @click="changeValidate(false)"
              variant="primary"

          >
            Todos
          </b-button>
        </b-col>
        <b-col cols="12" lg="4" class="mt-2">
          <b-form-group
              label="Fecha"
          >
            <flat-pickr
                v-model="send_date"
                class="form-control"
                :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
            />
            <div v-if="send_date"
                 style="position: absolute; margin-top: -28px; right: 30px; z-index: 100; cursor: pointer"
                 @click="clearDate">
              <strong>X</strong>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="4" class="mt-2">
          <div style="display: flex">
            <b-button
                style="margin-top: 25px"
                @click="initialize"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Buscar
            </b-button>
          </div>

        </b-col>

        <b-col cols="12" lg="4" class="mt-2">


          <b-form-checkbox-group
              v-model="selectedStates"
              name="event-filter"
              stacked
              @change="initialize"
          >
            <b-row>
              <b-col cols="6" class="mt-2" v-for="item in dataStates" :key="item">
                <b-form-checkbox

                    :key="item"
                    :id="`a${item}`"
                    name="event-filter"
                    :value="item"
                    class="mb-1"
                    :class="`custom-control-${item}`"
                >
                  {{ changeTextSpanish(item) }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>

        </b-col>

        <b-form-group>

        </b-form-group>
      </b-row>
      <b-row>
        <b-col
            style="margin-top: 20px"
            cols="12" lg="6"
        >
          <b-form-group
              label="Buscar por"

          >
            <b-input-group size="md">
              <b-form-input
                  id="basicInput"
                  type="search"
                  @search="resetFilter"
                  v-model="nameSearch"
                  placeholder="Buscar"
              />

              <v-select
                  style="width: 150px; border-radius: 1px !important;"
                  v-model="typeSearch"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="optionsSearch"
              />
              <b-input-group-append>
                <div style="display: flex">
                  <b-button
                      style="margin-left: 5px"
                      @click="searchSend"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      aria-controls="sidebar-add-new-event"

                      variant="primary"

                  >
                    Buscar
                  </b-button>
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
            cols="12" lg="6"
        >
          <div style=" width: 100%; margin-top: 20px">
            <div style="display: flex; justify-content: end">
              <div>
                <b-form-group

                    label="Seleccione el orden"
                >
                  <v-select
                      style="width: 100%; border-radius: 1px !important;"
                      v-model="typeSort"
                      @input="initialize"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="optionsSort"
                  />
                </b-form-group>
              </div>
              <div style="margin-left: 30px; margin-top: 20px">
                <div style="display: flex">
                  <div style="cursor: pointer" @click="changeSort('DESC')">
                    <div :style="dynamicDesc">

                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-autofit-up"
                           width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                           fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 4h-6a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h8"/>
                        <path d="M18 20v-17"/>
                        <path d="M15 6l3 -3l3 3"/>
                      </svg>
                    </div>
                    Desc
                  </div>
                  <div style="margin-left: 50px; cursor: pointer" @click="changeSort('ASC')">
                    <div :style="dynamicAsc">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-autofit-down"
                           width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                           fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 20h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8"/>
                        <path d="M18 4v17"/>
                        <path d="M15 18l3 3l3 -3"/>
                      </svg>
                    </div>
                    Asc
                  </div>


                </div>
              </div>
            </div>
          </div>
        </b-col>


        <b-col cols="12">
          <b-table
              striped
              hover
              responsive
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :filter="filter"
              :filter-included-fields="filterOn"
          >

            <template #cell(actions)="data">
                              <span @click="showModalFiles(data.value.id)" style="margin-right: 10px">
            <feather-icon v-b-tooltip.hover
                          title="Descargar Archivos" icon="FolderIcon" style="color: darkcyan; " size="25"/>
              </span>
              <span v-if="!data.value.validate_appointment">

              </span>
              <span v-else>
                <span v-if="!data.value.validated">

                  <a style="margin-right: 10px" v-if="!data.value.id_treatment"
                     @click=createTreatment(data.value.id)
                  >
                    <feather-icon v-b-tooltip.hover
                                  title="Generar tratamiento" icon="FileTextIcon" style="color: darkblue" size="25"/>
                  </a>
                  <a style="margin-right: 10px" v-else
                     @click=editTreatment(data.value.id_treatment)>
                    <feather-icon v-b-tooltip.hover
                                  title="Modificar Tratamiento" icon="EyeIcon" style="color: darkblue" size="25"/>
                  </a>
                  <span
                      v-if="!data.value.validated && getUser.appointee_id && data.value.id_treatment && !data.value.sale_order || !data.value.validated && getUser.department_id === 6 && data.value.id_treatment && !data.value.sale_order">
                <feather-icon v-b-tooltip.hover @click="validateTreatment(data.value.id, getUser.id)"
                              title="Crear orden" icon="UserCheckIcon" style="color: darkgreen" size="25"/>
                </span>
                  <span
                      v-if="!data.value.validated && getUser.appointee_id && data.value.id_treatment && data.value.sale_order|| !data.value.validated && getUser.department_id === 6 && data.value.id_treatment && data.value.sale_order">
                <feather-icon v-b-tooltip.hover
                              @click="confirmOrderNew(data.value.id, getUser.id, data.value.sale_order, data.value.id_treatment)"
                              title="Confirmar Orden" icon="CheckCircleIcon" style="color: darkgreen" size="25"/>
                </span>
                  <span v-if="data.value.check_exit">
                    <feather-icon v-b-tooltip.hover
                                  title="Estatus Actualizados" icon="LogOutIcon" style="color: darkgreen" size="25"/>
                    </span>
                </span>
                <span v-else>


                  <a style="margin-right: 10px" v-if="data.value.id_treatment"
                     @click=editTreatment(data.value.id_treatment)
                  >
                    <feather-icon v-b-tooltip.hover
                                  title="Ver Tratamiento" icon="EyeIcon" style="color: darkblue" size="25"/>
                  </a>
                  <feather-icon v-b-tooltip.hover v-if="data.value.verify_stock"
                                title="Tratamiento validado" icon="CheckIcon" style="color: darkgreen" size="25"/>
                  <span v-else-if="getUser.appointee_id || getUser.department_id === 6 && !data.value.deleteSale">
                <feather-icon v-b-tooltip.hover @click="validateStock(data.value.stock_id, data.value.id)"
                              title="Abastecer Medicamento" icon="ShoppingCartIcon" style="color: darkgreen" size="25"/>
                  </span>
                      <span v-if="data.value.check_exit">
                <feather-icon v-b-tooltip.hover
                              title="Estatus Actualizados" icon="LogOutIcon" style="color: darkgreen" size="25"/>
                </span>

                </span>
              </span>


            </template>
          </b-table>
        </b-col>

        <b-col
            cols="12"
        >
          <b-pagination
              @change="changePage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
          />
        </b-col>
      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal, BFormCheckbox, BFormCheckboxGroup

} from 'bootstrap-vue'
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from 'vue-good-table'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";
import ModalCreateTreatment from "@/components/Appointments/ModalCreateTreatment"
import ModalEditTreatment from "@/components/Appointments/ModalEditTreatment"
import io from "socket.io-client";
import {createTimeReal, urlSocket} from "@/sockets/socket";
import ModalCreateAppointment from "@/components/Appointments/ModalCreateAppointment.vue";

export default {
  name: "ListTreatment",
  components: {
    ModalCreateAppointment,
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip, BModal,
    VBModal,
    ModalAppointment, flatPickr, ModalCreateTreatment, ModalEditTreatment, BFormCheckbox, BFormCheckboxGroup, vSelect
  },
  data() {
    return {
      socket: io(urlSocket),
      typeAppointment: 'tratamiento',
      chargeModal: false,
      dateSelect: null,
      doctSelect: null,
      file: null,
      send_date: null,
      typeSearch: {id: "searchPatient", value: "Paciente"},
      optionsSearch: [
        {id: "searchPatient", value: "Paciente"},
        {id: "searchPlace", value: "Sala"},
        {id: "searchId", value: "ID"},
      ],
      typeSort: {id: "id", value: "ID"},
      optionsSort: [
        {id: "id", value: "ID"},
        {id: "app_dt_start", value: "Fecha de cita"},
        {id: "customer", value: "Paciente"},
      ],
      typeOrder: 'DESC',
      optionsOrder: [
        {id: "asc", value: "ASC"},
        {id: "desc", value: "DESC"},
      ],
      sort: null,
      fieldSort: null,
      file2: null,
      file3: null,
      file4: null,
      nameSearch: null,
      id_apoi: null,
      treatment_id: null,
      id_treatment: null,
      date_appointment: null,
      name_pacient_appointment: null,
      perPage: 10,
      selectedStates: ['new', 'approved', 'pending'],
      dataStates: ['new', 'approved', 'rejected', 'pending'],
      show: false,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      sortBy: 'desc',
      validateCheck: false,
      sortDesc: false,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id', label: 'id', sortable: true
        },
        {
          key: 'customer', label: 'Paciente'
        },
        {
          key: 'nurse', label: 'Doctor encargado'
        },
        {
          key: 'start', label: 'Hora inicio'
        },
        {
          key: 'end', label: 'Hora Salida'
        },

        {key: 'place', label: 'Sala', sortable: true},
        {key: 'validate', label: 'Validado Por'},
        {
          key: 'actions', label: 'Estatus',
        },
      ],
      items: [],
    }
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({text: f.label, value: f.key}))

      // Create an options list from our fields
    },
    dynamicDesc() {
      if (this.typeOrder === 'DESC') {
        return {
          backgroundColor: '#19112E',
          borderRadius: '10px',
          color: 'white'

        };
      }
    },
    dynamicAsc() {
      if (this.typeOrder === 'ASC') {
        return {
          backgroundColor: '#19112E',
          borderRadius: '10px',
          color: 'white'

        };
      }
    }

  },
  mounted() {
    this.$root.$on('closeComponent', async () => {
      // your code goes here
      await this.closeModal()
    })
    this.socket.on('LIST_TREATMENT', async (data) => {
      //await this.initialize();
      if (this.$route.query.appointment_id) {
        await this.showModalFiles(parseInt(this.$route.query.appointment_id))
      }
      if (this.$route.query.treatment_id) {
        await this.editTreatment(parseInt(this.$route.query.treatment_id))
      }
      // you can also do this.messages.push(data)
    });
  },
  async created() {
    if (this.getUser.work_location_id){
      await this.initialize();
      if (this.$route.query.appointment_id) {
        await this.showModalFiles(parseInt(this.$route.query.appointment_id))
      }
      if (this.$route.query.treatment_id) {
        await this.editTreatment(parseInt(this.$route.query.treatment_id))
      }
    }



  },
  methods: {
    ...mapActions('treatment', ['findTreatment', 'findImgVerify', 'findImgVerifyModal', 'validateCount', 'findTreatmentDate',
      'findTreatmentValidate', 'getTreatmentAllFilter']),
    ...mapActions('appointment', ['downloadFileAppointment', 'createSaleOrder', 'updateStockOrder', 'downloadFileAppointmentAll', 'createConfirmOrder']),
    async closeModalReload(){
      this.doctSelect = null;
      this.dateSelect = null;
      await this.$bvModal.hide('modal-appointment');
      if (this.getUser.work_location_id){
        await this.initialize();
        if (this.$route.query.appointment_id) {
          await this.showModalFiles(parseInt(this.$route.query.appointment_id))
        }
        if (this.$route.query.treatment_id) {
          await this.editTreatment(parseInt(this.$route.query.treatment_id))
        }
      }
    },
    async addAppointment() {

      await this.$bvModal.show('modal-appointment')
    },

    async showModalFiles(id) {
      this.show = true
      this.id_apoi = id
      this.show = false
      await this.$bvModal.show('modal-prevent-closing');

      await this.$router.push(`/tratemientos?appointment_id=${this.id_apoi}`).catch(() => {
      });
    },
    async resetModal() {

      await this.$router.push("/tratemientos").catch(() => {
      });
    },
    async createTreatment(id) {
      this.id_treatment = id
      await this.$bvModal.show('modal-prevent-closing-treatment');

    },
    async editTreatment(id) {

      this.id_treatment = id
      await this.$bvModal.show('modal-prevent-closing-treatment-edit');
      await this.$router.push(`/tratemientos?treatment_id=${this.id_treatment}`).catch(() => {
      });


    },

    async closeModal() {
      this.id_apoi = null
      await this.$bvModal.hide('modal-prevent-closing')
      await this.$bvModal.hide('modal-prevent-closing-treatment')
      await this.$bvModal.hide('modal-prevent-closing-treatment-edit')
      await this.initialize();

    },
    async validateStock(id, treatment_id) {

      if (!id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al abastecer verifique con el administrador',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      const response = await this.updateStockOrder({id, treatment_id})
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validado Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.validateCount()
        await this.initialize();
        await createTimeReal(true);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al validar',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }

    },
    async validateTreatment(id, appointee_id) {
      this.show = true
      const send = {
        id,
        appointee_id
      }
      const response = await this.createSaleOrder(send)
      if (response) {

        setTimeout(async () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validado Correctamente',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.show = false
          await this.initialize();
          await createTimeReal(true);

        }, 4000);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al validar',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
      }
    },
    async confirmOrderNew(id, appointee_id, order_id, treatment) {

      this.show = true
      const send = {
        id,
        appointee_id,
        order_id,
        treatment
      }
      const response = await this.createConfirmOrder(send)
      if (response.status) {
        setTimeout(async () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Orden confirmada',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.show = false
          await this.initialize();
          await createTimeReal(true);
        }, 4000);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.faultString,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
      }
    },
    async downloads(id, file, name, partner, file_number) {
      if (!file) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No hay archivo por descargar',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
        file_number
      }
      await this.downloadFileAppointmentAll(send)
    },

    async changeValidate(status) {
      this.currentPage = 1;
      this.validateCheck = status;
      await this.initialize();
    },
    async clearDate() {
      this.currentPage = null;
      this.totalRows = null;
      this.send_date = null;
      await this.initialize();
    },
    async searchSend() {
      if (this.nameSearch) {
        this.currentPage = 1;
        this.totalRows = null;
        await this.initialize()
        if (!this.nameSearch) {
          this.currentPage = 1;
          this.totalRows = null;
          await this.initialize()
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'El campo esta vacio',
            icon: 'error',
            variant: 'warning',
          },
        });
      }


    },
    async changePage(page) {
      this.currentPage = page
      await this.initialize()

    },

    async changeSort(value) {
      this.currentPage = 1;
      this.typeOrder = value;
      await this.initialize()

    },
    async resetFilter() {
      if (!this.nameSearch) {
        this.currentPage = 1;
        this.totalRows = null;
        this.nameSearch = null;
        await this.initialize();
      }
    },
    async initialize() {
      const queryParams = {};
      if (this.getUser.department_id === 13) {
        if (this.getUser.appointee_id) {
          queryParams.appointee_id = this.getUser.appointee_id
        }
      }
      queryParams.size = this.perPage
      if (this.currentPage > 1) {
        queryParams.page = this.currentPage
      } else {
        this.totalRows = null;
        this.currentPage = 1
        queryParams.page = 1
      }
      if (this.typeSort.id && this.typeOrder) {
        queryParams.sort = this.typeOrder
        queryParams.fieldSort = this.typeSort.id
      }
      if (this.send_date) {
        queryParams.appoint_date = this.send_date
      }
      if (this.selectedStates.length === 0) {
        queryParams.arrayState = 'empty'
      } else {
        queryParams.arrayState = this.selectedStates
      }
      queryParams.validate = !this.validateCheck
      if (this.nameSearch) {
        if (this.typeSearch.id === 'searchPatient') {
          queryParams.searchPatient = this.nameSearch
        }
        if (this.typeSearch.id === 'searchPlace') {
          queryParams.searchPlace = this.nameSearch
        }
        if (this.typeSearch.id === 'searchId') {
          if (parseInt(this.nameSearch)) {
            queryParams.searchId = this.nameSearch
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No se puede mandar caracteres en un id',
                icon: 'error',
                variant: 'warning',
              },
            });
            return
          }
        }
      }
      this.items = [];
      const queryString = Object.keys(queryParams)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&');

      const response = await this.getTreatmentAllFilter(queryString);

      this.totalRows = response.pagination.totalItems;
      for (const a of response.data) {
        if (a.ClinicTreatment) {
          if (a.Sale) {

            if (a.ClinicTreatment.HrEmployeeVali) {
              if (a.Sale.StockPickingAlls.length > 0 && a.Sale) {

                let filteredItems = a.Sale.StockPickingAlls.filter(item => item.name.search("OUT") !== -1);


                if (filteredItems[0]) {
                  this.items.push({
                    id: a.id,
                    validate: a.ClinicTreatment.HrEmployeeVali.name,
                    customer: a.ResPartner.name,
                    day: a.appoint_date,
                    nurse: a.ResPartner.ResPartnerAppointment ? a.ResPartner.ResPartnerAppointment.name : '------',
                    start: this.resHoursOdoo(a.app_dt_start),
                    end: this.resHoursOdoo(a.app_dt_stop),
                    place: a.ResPartnerAppointment.name,
                    actions: {
                      id: a.id,
                      date: a.appoint_date,
                      name_pacient: a.ResPartner.name,
                      sale_order: a.Sale.id,
                      stock_id: a.Sale.StockPickingAlls.length > 0 ? filteredItems[0].id : '',
                      verify_stock: a.Sale.StockPickingAlls.length > 0 ? filteredItems[0].patient_file_number : '',
                      id_treatment: a.ClinicTreatment.id,
                      nurse: a.ClinicTreatment.charge_nurse,
                      received: a.ClinicTreatment.treatment_received,
                      validated: a.ClinicTreatment.treatment_validated,
                      validate_appointment: a.validate_appointment,
                      delivered: a.ClinicTreatment.treatment_delivered,
                      check_exit: a.ClinicTreatment.check_exit,
                    }
                  })
                }else{

                }
              } else if (a.Sale.StockPickingAlls.length === 0 && a.Sale.state === 'sale') {
                this.items.push({
                  id: a.id,
                  validate: a.ClinicTreatment.HrEmployeeVali.name,
                  customer: a.ResPartner.name,
                  day: a.appoint_date,
                  nurse: a.ResPartner.ResPartnerAppointment ? a.ResPartner.ResPartnerAppointment.name : '------',
                  start: this.resHoursOdoo(a.app_dt_start),
                  end: this.resHoursOdoo(a.app_dt_stop),
                  place: a.ResPartnerAppointment.name,
                  actions: {
                    id: a.id,
                    date: a.appoint_date,
                    name_pacient: a.ResPartner.name,
                    sale_order: a.Sale.id,
                    stock_id: null,
                    deleteSale: true,
                    verify_stock: null,
                    id_treatment: a.ClinicTreatment.id,
                    nurse: a.ClinicTreatment.charge_nurse,
                    received: a.ClinicTreatment.treatment_received,
                    validated: a.ClinicTreatment.treatment_validated,
                    validate_appointment: a.validate_appointment,
                    delivered: a.ClinicTreatment.treatment_delivered,
                    check_exit: a.ClinicTreatment.check_exit,
                  }
                })
              }else{

              }

            } else if (a.Sale?.StockPickingAlls.length > 0 && a.Sale) {
              let filteredItems = a.Sale.StockPickingAlls.filter(item => item.name.search("OUT") !== -1);
              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ResPartner.ResPartnerAppointment ? a.ResPartner.ResPartnerAppointment.name : '------',
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: a.Sale.StockPickingAlls.length > 0 ? filteredItems[0].id : '',
                  verify_stock: a.Sale.StockPickingAlls.length > 0 ? filteredItems[0].patient_file_number : '',
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            } else {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ResPartner.ResPartnerAppointment ? a.ResPartner.ResPartnerAppointment.name : '------',
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: 'confirm_order',
                  verify_stock: 'confirm_order',
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            }
          } else {

            this.items.push({
              id: a.id,
              customer: a.ResPartner.name,
              validate: null,
              day: a.appoint_date,
              nurse: a.ResPartner.ResPartnerAppointment ? a.ResPartner.ResPartnerAppointment.name : '------',
              start: this.resHoursOdoo(a.app_dt_start),
              end: this.resHoursOdoo(a.app_dt_stop),
              place: a.ResPartnerAppointment.name,
              actions: {
                id: a.id,

                date: a.appoint_date,
                name_pacient: a.ResPartner.name,
                sale_order: null,
                stock_id: null,
                verify_stock: null,
                id_treatment: a.ClinicTreatment.id,
                nurse: a.ClinicTreatment.charge_nurse,
                validate_appointment: a.validate_appointment,
                received: a.ClinicTreatment.treatment_received,
                validated: a.ClinicTreatment.treatment_validated,
                delivered: a.ClinicTreatment.treatment_delivered,
                check_exit: a.ClinicTreatment.check_exit,
              }
            })
          }

        } else {

          this.items.push({
            id: a.id,
            customer: a.ResPartner.name,
            day: a.appoint_date,
            validate: null,
            start: this.resHoursOdoo(a.app_dt_start),
            end: this.resHoursOdoo(a.app_dt_stop),
            nurse: a.ResPartner.ResPartnerAppointment.name,
            place: a.ResPartnerAppointment.name,
            actions: {
              id: a.id,
              date: a.appoint_date,
              name_pacient: a.ResPartner.name,
              id_treatment: null,
              sale_order: null,
              nurse: null,
              validate_appointment: a.validate_appointment,
              stock_id: null,
              verify_stock: null,
              received: null,
              validated: null,
              delivered: null,
              check_exit: null
            }
          })
        }
      }

    },

  },


}
</script>

<style>
.vs__dropdown-toggle {
  border-radius: 1px !important;
}

</style>
